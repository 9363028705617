.logo {
  margin-bottom: 12px;
  width: auto;
  height: 50px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
}

.arrowIcon {
  width: 24px;
  height: 14px;
  fill: var(--mui-palette-primary-500);
}

.link {
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  color: var(--mui-palette-primary-500);
}

@media (max-width: 520px) {
  .form {
    flex-direction: column;
  }
}
