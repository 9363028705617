.formSection {
  background-color: var(--mui-palette-surfaces-layerOne);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 60px;
}

@media (max-width: 1240px) {
  .heroSection {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .formSection {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
}

@media (max-width: 860px) {
  .page {
    grid-template-columns: auto;
    grid-template-rows: 115px auto;
  }

  .heroSection {
    align-items: flex-start;
    width: auto;
    flex: 0;
  }

  .formSection {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-top: 36px;
    padding-left: 0;
  }
}

@media (max-width: 510px) {
  .page {
    grid-template-rows: 100px auto;
  }
}
