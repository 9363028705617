.formContainer {
  width: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 24px;
}

.errorMessageContainer {
  margin-bottom: 16px;
}

.errorMessageContainer a {
  display: block;
  color: var(--mui-palette-error-main) !important;
  margin-top: 6px;
}

.logo {
  margin-bottom: 12px;
  width: auto;
  height: 50px;
}

.subHeader {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.formContainer a {
  display: flex;
  align-items: center;
  margin-left: 6px;
  color: var(--mui-palette-primary-600);
}

.form {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.formTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  gap: 8px;
}

.formInput {
  padding-bottom: 8px;
}

.recaptchaError {
  padding: 0;
  color: var(--mui-palette-error-main);
}

.button {
  margin-top: 24px;
}

.arrowIcon {
  width: 24px;
  height: 14px;
}
.arrowIconErr {
  width: 24px;
  height: 14px;
  fill: var(--mui-palette-error-main);
}

@media (max-width: 1240px) {
  .formContainer {
    width: auto;
  }
}

@media (max-width: 767px) {
  .formContainer {
    margin: 0 auto;
  }

  .form {
    max-width: calc(100vw - 64px);
  }
}

@media (max-width: 410px) {
  .form {
    max-width: calc(100vw - 64px);
  }

  .formTitle {
    align-items: flex-start;
  }
}
